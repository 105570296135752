import React from "react"
import {SectionWrapper} from "shared/Styles/Wrappers"
import {Card} from "components/MenteeDetails"
import {Flex, StyledGlobalWrapper} from "./styles"
import uuid from "react-uuid";

const MenteesList = ({data}) => (
  <StyledGlobalWrapper>
    <SectionWrapper>
      <Flex>
        {
          data.map((el) => <Card key={uuid()} menteeID={el.node.frontmatter.slug} mentee={el.node.frontmatter}/>)
        }
      </Flex>
    </SectionWrapper>
  </StyledGlobalWrapper>
);

export {MenteesList};
