import React from "react"
import SEO from "components/seo"
import { PageHeader } from "components/PageHeader"
import { MenteesList } from "components/MenteesList"
import { graphql } from "gatsby"

const NasiPodopieczni = ({ data }) => (
  <>
    <SEO
      title={"Podopieczni"}
      description={
        "Dokonajcie wpłaty na konto fundacji. Fundusze pokryją koszty kursów. A my pozostaniemy z Państwem w kontakcie, aby pokazać, na jakie cele zostały przekazane fundusze."
      }
    />
    <PageHeader>Nasi podopieczni</PageHeader>
    <MenteesList data={data.allMdx.edges} />
  </>
)

export const query = graphql`
  {
    allMdx {
      edges {
        node {
          id
          slug
          frontmatter {
            name
            age
            wantToBe
            avatar {
              publicURL
            }
            about
            color
            dream
            hobby
            slug
            title
          }
        }
      }
    }
  }
`

export default NasiPodopieczni
