import styled from "styled-components"
import { GlobalWrapper } from "shared/Styles/Wrappers"

export const StyledGlobalWrapper = styled(GlobalWrapper)`
  background-color: #f6f6f6;
  padding-top: 64px;
  padding-bottom: 56px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 64px;
    padding-bottom: 152px;
  }
`

export const Flex = styled.div`
margin-top: 1rem;
display: flex;
flex-wrap:wrap;
justify-items:center;

`
